import React from "react"
import { navigate } from "gatsby"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Paragraph from "../../../../../components/paragraph"
import Note from "../../../../../components/note"
import Input from "../../../../../components/input"
import Text from "../../../../../components/text"
import Inline from "../../../../../components/inline"
import Box from "../../../../../components/box"
import TaskSidebar from "../../../../../components/task-sidebar"
import PaperStack from "../../../../../components/paper-stack"
import Poem from "../../../../../components/poem"

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          courseId: "reiseerinnerung",
          chapterId: "02-dichterwerkstatt",
          taskId: "strophen-und-verse",
        })
        navigate(
          "/kurse/reiseerinnerung/02-dichterwerkstatt/strophen-und-verse/loesung"
        )
      }}
      footer={<CourseFooterNext type="submit" value="submit" />}
      navigation={navigation}
    >
      <Seo title="Strophen & Verse zählen" />
      <Stack>
        <Heading as="h2" level={2}>
          Strophen & Verse zählen
        </Heading>
        <Paragraph>
          Hölderlin hat seine Gedichte wie Musikstücke komponiert. Der formale
          Aufbau der Strophen und die rhythmische und metrische Taktung der
          Verse standen für ihn am Ausgangspunkt seines Schaffens. Dabei
          experimentierte er häufig mit komplexen antiken Versmaßen, die er als
          Muster zitierte, aber auch innovativ variierte.
        </Paragraph>
        <Note variant="task">
          Sieh dir die äußere Form des Gedichts genauer an und suche nach
          Mustern.
        </Note>

        <TaskSidebar
          main={
            <PaperStack>
              <Poem size={[3, 3, 4]}>
                <p>Der Nordost wehet, </p>
                <p>Der liebste unter den Winden </p>
                <p>Mir, weil er feurigen Geist </p>
                <p>Und gute Fahrt verheißet den Schiffern. </p>
                <p>Geh aber nun und grüße </p>
                <p>Die schöne Garonne, </p>
                <p>Und die Gärten von Bourdeaux </p>
                <p>Dort, wo am scharfen Ufer </p>
                <p>Hingehet der Steg und in den Strom </p>
                <p>Tief fällt der Bach, darüber aber </p>
                <p>Hinschauet ein edel Paar </p>
                <p>Von Eichen und Silberpappeln;</p>
                <br />
                <p>Noch denket das mir wohl und wie </p>
                <p>Die breiten Gipfel neiget </p>
                <p>Der Ulmwald , über die Mühl', </p>
                <p>Im Hofe aber wächset ein Feigenbaum. </p>
                <p>An Feiertagen gehn </p>
                <p>Die braunen Frauen daselbst </p>
                <p>Auf seidnen Boden, </p>
                <p>Zur Märzenzeit, </p>
                <p>Wenn gleich ist Nacht und Tag, </p>
                <p>Und über langsamen Stegen, </p>
                <p>Von goldenen Träumen schwer. </p>
                <p>Einwiegende Lüfte ziehen.</p>
                <br />
                <p>Es reiche aber, </p>
                <p>Des dunkeln Lichtes voll, </p>
                <p>Mir einer den duftenden Becher,</p>
                <p>Damit ich ruhen möge; denn süß </p>
                <p>Wär' unter Schatten der Schlummer. </p>
                <p>Nicht ist es gut, </p>
                <p>Seellos von sterblichen </p>
                <p>Gedanken zu seyn. Doch gut </p>
                <p>Ist ein Gespräch und zu sagen </p>
                <p>Des Herzens Meinung, zu hören viel </p>
                <p>Von Tagen der Lieb’, </p>
                <p>Und Thaten, welche geschehen.</p>
                <br />
                <p>Wo aber sind die Freunde? Bellarmin </p>
                <p>Mit dem Gefährten? Mancher </p>
                <p>Trägt Scheue, an die Quelle zu gehn; </p>
                <p>Es beginnet nemlich der Reichtum </p>
                <p>Im Meere. Sie, </p>
                <p>Wie Mahler, bringen zusammen </p>
                <p>Das Schöne der Erd' und verschmähn </p>
                <p>Den geflügelten Krieg nicht, und </p>
                <p>Zu wohnen einsam, jahrlang, unter </p>
                <p>Dem entlaubten Mast, wo nicht die Nacht durchglänzen </p>
                <p>Die Feiertage der Stadt, </p>
                <p>Und Saitenspiel und eingeborener Tanz nicht.</p>
                <br />
                <p>Nun aber sind zu Indiern </p>
                <p>Die Männer gegangen, </p>
                <p>Dort an der luftigen Spiz’ </p>
                <p>An Traubenbergen, wo herab </p>
                <p>Die Dordogne kommt.</p>
                <p>Und zusammen mit der prächt'gen </p>
                <p>Garonne meerbreit </p>
                <p>Ausgehet der Strom. Es nehmet aber</p>
                <p>Und giebt Gedächtniß die See, </p>
                <p>Und die Lieb’ auch heftet fleißig die Augen, </p>
                <p>Was bleibet aber, stiften die Dichter.</p>
              </Poem>
            </PaperStack>
          }
          sidebar={
            <Stack space={8}>
              <Text>Das Gedicht hat 5 Strophen.</Text>
              <Stack space={3}>
                <Inline alignY="center">
                  <Text>Die Strophe 1 hat</Text>
                  <Box sx={{ width: 12 }}>
                    <Input
                      type="number"
                      min="1"
                      max="20"
                      id="verse1"
                      name="verse1"
                      placeholder="-"
                    />
                  </Box>
                  <Text>Verse.</Text>
                </Inline>
                <Inline alignY="center">
                  <Text>Die Strophe 2 hat</Text>
                  <Box sx={{ width: 12 }}>
                    <Input
                      type="number"
                      min="1"
                      max="20"
                      id="verse2"
                      name="verse2"
                      placeholder="-"
                    />
                  </Box>
                  <Text>Verse.</Text>
                </Inline>
                <Inline alignY="center">
                  <Text>Die Strophe 3 hat</Text>
                  <Box sx={{ width: 12 }}>
                    <Input
                      type="number"
                      min="1"
                      max="20"
                      id="verse3"
                      name="verse3"
                      placeholder="-"
                    />
                  </Box>
                  <Text>Verse.</Text>
                </Inline>
                <Inline alignY="center">
                  <Text>Die Strophe 4 hat</Text>
                  <Box sx={{ width: 12 }}>
                    <Input
                      type="number"
                      min="1"
                      max="20"
                      id="verse4"
                      name="verse4"
                      placeholder="-"
                    />
                  </Box>
                  <Text>Verse.</Text>
                </Inline>
                <Inline alignY="center">
                  <Text>Die Strophe 5 hat</Text>
                  <Box sx={{ width: 12 }}>
                    <Input
                      type="number"
                      min="1"
                      max="20"
                      id="verse5"
                      name="verse5"
                      placeholder="-"
                    />
                  </Box>
                  <Text>Verse.</Text>
                </Inline>
              </Stack>
            </Stack>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
